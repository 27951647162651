<template>
    <section class="c-dashboard">
        <app-intro>
            <h1>Dashboard</h1>

            <div class="c-filters">
                <select name="month" class="form-control form-control--light" id="month" v-model="selectedMonth" @change="onChangeSelect($event)">
                    <option value="x">Alles</option>
                    <option value="1">Januari</option>
                    <option value="2">Februari</option>
                    <option value="3">Maart</option>
                    <option value="4">April</option>
                    <option value="5">Mei</option>
                    <option value="6">Juni</option>
                    <option value="7">Juli</option>
                    <option value="8">Augustus</option>
                    <option value="9">September</option>
                    <option value="10">Oktober</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                </select>

                <select name="year" class="form-control form-control--light" id="year" v-model="selectedYear" @change="onChangeSelect($event)">
                    <option value="x" disabled>Kies een jaar</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                </select>
            </div>
        </app-intro>

        <div class="c-loading" v-if="loading">
            <spinner/>
            Bezig met laden van de statistieken...
        </div>

        <transition-group class="c-dashboard-list" name="fade" tag="section" v-if="!loading">
            <div class="c-node" key="statistics">
                <span class="c-node__value">{{ statistics.configurations }}</span>
                <span class="c-node__title">Configuraties</span>
            </div>

            <div class="c-node" key="users">
                <span class="c-node__value">{{ statistics.users }}</span>
                <span class="c-node__title">Gebruikers</span>
            </div>

            <div class="c-node" key="price_indications">
                <span class="c-node__value">{{ statistics.price_indications }}</span>
                <span class="c-node__title">Prijs aanvragen</span>
            </div>
        </transition-group>

    </section>
</template>

<script>
import { mapState } from 'vuex';
import AppIntro from '../components/app/AppIntro.vue';
import Spinner from '../components/Spinner.vue';

export default {
    components: { Spinner, AppIntro },
    data () {
        return {
            loading: true,
            selectedMonth: 'x',
            currentMonth: new Date().getMonth(),
            selectedYear: new Date().getFullYear(),
            currentYear: new Date().getFullYear(),
        };
    },
    computed: {
        ...mapState({
            statistics: (state) => state.statistics,
        }),
    },
    methods: {
        async onChangeSelect () {
            this.loading = true;

            const data = {};

            if (this.selectedMonth !== 'x')
            {
                data.month = this.selectedMonth;
            }

            if (this.selectedYear !== 'x')
            {
                data.year = this.selectedYear;
            }

            await this.$store.dispatch('statistics/getAll', data);

            this.loading = false;
        },
    },
    async created () {
        await this.$store.dispatch('statistics/getAll');

        this.loading = false;
    },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/essentials";

.c-intro {
    display: grid;
    grid-gap: rem(15);

    @include media-breakpoint-up(sm) {
        grid-template-columns: 1fr 1fr;
    }
}

.c-filters {
    display: grid;
    grid-gap: rem(15);

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.c-dashboard-list {
    display: grid;
    grid-gap: rem(15);

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.c-node {
    background-color: $white;
    border: rem(1) solid $gray-light;
    padding: rem(30);
    text-align: center;

    &__value {
        @include fluid-type(30px, 100px);
        display: block;
        line-height: 1.25;
    }

    &__title {

    }
}
</style>
